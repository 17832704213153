.logo {
    width: 250px;
}

/*.register {*/
/*    background-color: white;*/
/*}*/

.red {
    background-color: rgb(153, 40, 58);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Proxima Nova, system-ui, sans-serif;
    }
}

html {
    background-color: rgb(153, 40, 58);
}
